@import 'assets/css/common.scss';

.helper-text {
  visibility: hidden;
  font-size: $fontSize-xs;
  font-weight: 300;
  display: flex;
  margin: 0 0;
  margin-top: 0.8rem;
  &_icon {
    margin-right: 1rem;
    flex-shrink: 0;
  }
  &.asterix {
    display: unset;
    font-size: $fontSize-s;
    font-weight: 700;
  }
  &.visible {
    color: red;
    visibility: visible;
  }
}
